import {environment} from '../../../environments/environment';
import {Opening, OpeningWithPosition} from "./opening";
import {Size} from "./size";
import {SizeRange} from "./sizeRange";
import {SizeService} from "../services/size.service";
import {ProjectService} from "../services/project.service";
import {ApplicationError} from "./application-error";
import {EventEmitter} from "@angular/core";
import {Design} from './design';
import {Shape} from './shape';
import {firstValueFrom, of} from "rxjs";

export class Project {

  public static DEFAULTS:any = {
    outer: new Size(8, 10),
    openingSize: new Size(4.5, 6.5),
    margin: [{min: 0, max: 18, value: 1.75},{min: 0, max: 36, value: 2},{min: 36, max: 72, value: 3}],
    vGrooveOffset: environment.vGroove.offset,
    reveal: 0.25
  };

  private _restrictions: any;
  public get restrictions(): any {
    return this._restrictions;
  }

  private _samples:Array<any> = new Array<any>();
  public get samples():Array<any> {
    return this._samples;
  }

  private _vgrooves:Array<any> = new Array<any>();
  public get vgrooves():Array<any> {
    return this._vgrooves;
  }

  public clearSamples(type:string = null):void {
    this._samples = type === null ? [] : this._samples.filter((sample) => {
      return sample.type !== type;
    });
    this._numSamples = this._samples.length;
    this.update();
  }

  public addSample(sample:any):void {
    if (this.samples.indexOf(sample) == -1) {
      this._samples.push(sample);
      this._numSamples = this._samples.length;
      this.update();
    }
  }

  public removeSample(sample:any):void {
    let idx = this.samples.indexOf(sample);
    if (idx > -1) {
      this._samples.splice(idx, 1);
      this._numSamples = this._samples.length;
      this.update();
    }
  }

  private _allowCustomization:boolean;
  public get allowCustomization():boolean {
    return this._allowCustomization;
  }
  public set allowCustomization(value:boolean) {
    this._allowCustomization = value;
  }

  _numSamples:number;
  public get numSamples():number {
    return this._numSamples;
  }

  private _primaryProduct:string;
  public get primaryProduct():string {
    return this._primaryProduct;
  }

  get canBuy():boolean {
    return this.moulding || this.numMats > 0 || this.glazing || this.backingBoard || this.bag || this.numSamples > 0;
  }

  _qty:number;
  get qty() { return this._qty }
  set qty(value:number) { this._qty = Math.max(value, 0); this.update();}

  _complexityUpCharge:number;
  get complexityUpCharge() { return this._complexityUpCharge }
  set complexityUpCharge(value:number) { this._complexityUpCharge = value; this.update();}

  _instructions:string;
  get instructions() { return this._instructions }
  set instructions(value:string) { this._instructions = value; this.update();}

  _moulding:any;
  get moulding() { return this._moulding }
  set moulding(value:any) {
    // validate the MDF moulding is not too thin for this project
    if (value && this.size.longerDimension > value.maxLongDimension) {
      throw new ApplicationError('THIN_MDF_NOT_ALLOWED', {
        oldValue: this._moulding,
        attemptedValue: value
      });
    }

    // update value has changed
    const previousValue = this._moulding;
    this._moulding = value;
    if (previousValue !== value) {
      this.update();
    }
  }

  _glazing:any;
  get glazing() { return this._glazing }
  set glazing(value:any) {
    if (value && value.thickness <= environment.glazingLimits.thickness) {
      if (this.size.shorterDimension > environment.glazingLimits.shorterDim || this.size.longerDimension > environment.glazingLimits.longerDim) {
        throw new ApplicationError('THIN_GLAZING_NOT_ALLOWED', {
          oldValue: this._glazing,
          attemptedValue: value
        });
      }
    }

    this._glazing = value;
    this.update();
  }

  _bag:any;
  get bag() { return this._bag }
  set bag(value:any) {
    this._bag = value;
    if (this._primaryProduct == 'SHOW-BAG') {
      let sz = this._bag.matSize.replace(/"/g,'').split(' x ');
      this.size.width = parseFloat(sz[0]);
      this.size.height = parseFloat(sz[1]);
    }
    this.update();
  }

  _backingBoard:any;
  get backingBoard() { return this._backingBoard }
  set backingBoard(value:any) { this._backingBoard = value; this.update();}

  _mirror:any;
  get mirror() { return this._mirror }
  set mirror(value:any) { this._mirror = value; this.update();}

  _reveal:number = 0.25;
  get reveal() { return this._reveal; }
  set reveal(value:number) {
    this._reveal = value;
    if (this._openings && this._openings.length > 0) {
      if (!this._openings[0].restrictions || !this._openings[0].restrictions.revealLocked ) {
        this._openings[0].reveal = value;
      }
    }
    this.updateAvailableOuterSizes();
    this.updateAvailableOpeningSizes();
    this.update();
  }

  get vGrooveOffset() {
    return this._openings && this._openings.length > 0 ? this._openings[0].vGrooveOffset : 0;
  }
  set vGrooveOffset(value: number) {
    if (this._openings && this._openings.length > 0) {
      for (var i = 0; i < this._openings.length; i++) {
        this._openings[i].vGrooveOffset = value;
      }
    }
    this.updateAvailableOuterSizes();
    this.updateAvailableOpeningSizes();
    this.update();
  }

  availableShapes: Array<Shape> = [];

  _preConfiguredProductId: string;
  id: string;
  originalId: string;
  originalIdForFirstPreview: string;
  version: number = 0;

  availableReveals: SizeRange = new SizeRange(0.125, 1, 0.125);

  _centeredMargins:any = {
    height: 0,
    width: 0
  };
  get centeredMargins():any { return this._centeredMargins }

  _availableBottomMargins: SizeRange;
  get availableBottomMargins(): SizeRange { return this._availableBottomMargins; }

  _availableVGrooveOffsets: SizeRange = new SizeRange(0, 0);
  get availableVGrooveOffsets(): SizeRange { return this._availableVGrooveOffsets; }

  get topCenterBottomMarginAvailable():boolean {
    return !isNaN(this.topCenterBottomMargin);
  }
  get topCenterBottomMargin():number {
    if (this.numOpenings > 0) {
      let minMargin = this.size.ui < environment.minMargins.ui ? environment.minMargins.minUnder : environment.minMargins.minOver;
      let margin = this.size.height - this.opening.size.height - this.centeredMargins.width;
      if (margin >= minMargin) {
        return margin;
      }
    }
    return NaN;
  }

  get margins(): any {
    if (this.openings && this.openings.length > 0) {
      return {
        left: this._centeredMargins.width,
        right: this._centeredMargins.width,
        top: this._centeredMargins.height - this.offset,
        bottom: this._centeredMargins.height + this.offset
      };
    }  else {
      return {
        left: NaN,
        right: NaN,
        top: NaN,
        bottom: NaN
      };
    }
  }

  private _specialBottomMargins:Array<any>;
  get specialBottomMargins():Array<any> {
    return this._specialBottomMargins;
  }

  get topCenterAndCenterBottomMarginAreDifferent():boolean {
    return this.topCenterBottomMargin !== this._centeredMargins.height;
  }

  _bottomMargin:number = 0;
  get bottomMargin() { return this._bottomMargin }
  set bottomMargin(value:number) {
    this._bottomMargin = value;
    this._openings.forEach( (opening: Opening) => {
      opening.offset = this.offset;
    });
    if (this.opening) {
      this.calculateAvailableVGrooveOffsets(this.opening);
    }
    this.update();
  }

  get offset():number {
    if (this.opening && !this.opening.size.incomplete && !isNaN(this._bottomMargin)) {
      let centeredMargin = (this.size.height - this.opening.size.height) / 2;
      return this._bottomMargin - centeredMargin;
    } else {
      return 0;
    }
  }

  private calculateAvailableBottomMargins(opening:Opening):void {

    let lastCenterBottomMargin, lastTopCenterBottomMargin;
    if (this._specialBottomMargins && this._specialBottomMargins.length > 0) {
      lastCenterBottomMargin = this._specialBottomMargins[0].value;
      lastTopCenterBottomMargin = this._specialBottomMargins[1] ? this._specialBottomMargins[1].value : NaN;
    }

    this._centeredMargins = {
      width: (this.size.width-opening.size.width)/2,
      height: (this.size.height-opening.size.height)/2
    };

    // if previous margin was top centered, then top center again
    if (this._bottomMargin === lastTopCenterBottomMargin) {
      this._bottomMargin = this.topCenterBottomMargin;
    }
    // if previous margin was centered, the centeer again
    else if (this._bottomMargin === lastCenterBottomMargin) {
      this._bottomMargin = this._centeredMargins.height;
    }

    const minMargin = (this.size.ui < environment.minMargins.ui ? environment.minMargins.minUnder : environment.minMargins.minOver) + this.reveal + this.vGrooveOffset;
    this._availableBottomMargins = new SizeRange(this._centeredMargins.height, this.size.height - opening.size.height - minMargin, 0.0625);

    // calculate the new center/top center values
    this._specialBottomMargins = new Array<any>();
    this._specialBottomMargins.push({
      value: this._centeredMargins.height,
      extraText: '(Centered)'
    });

    let topCenterBottomMargin = this.topCenterBottomMargin;
    if (topCenterBottomMargin > this._centeredMargins.height) {
      this._specialBottomMargins.push({
        value: topCenterBottomMargin,
        extraText: '(Top Centered)'
      });
    }

    // if bottom margin is no longer valid, re-center it
    if (isNaN(this._bottomMargin) || this._bottomMargin < this._availableBottomMargins.min || this._bottomMargin > this._availableBottomMargins.max) {
      this._bottomMargin = this._centeredMargins.height;
    }

    // make sure opening offsets match up with actual offset as it may have changed
    opening.offset = this.offset;
  }

  _size: Size;
  get size() { return this._size }
  set size(value: Size) {
    if (this._size) {
      this._size.change.unsubscribe();
    }

    this._size = value;
    this._size.change.subscribe( () => {
      this.updateAvailableOuterSizes();
      this.updateAvailableOpeningSizes();
      this.update();
    });
    this.update();
  }

  private updateAvailableOpeningSizes() {
    let opening = this._openings[0];
    if (opening) {
      opening.size.updateRange(this.size, 'outer', this.reveal);
      this.calculateAvailableVGrooveOffsets(opening);
      this.calculateAvailableBottomMargins(opening);
    }
  }

  public get partsUsed(): Array<string> {
    const retValue = new Array<string>();
    ['topMat', 'bottomMat', 'backingBoard', 'glazing', 'mirror', 'moulding', 'vGrooveOffset'].forEach( (prop) => {
        if (this[prop]) {
          const catType = this[prop].categoryType ? this[prop].categoryType : prop.replace('Offset', '').toUpperCase();
          if (retValue.indexOf(catType) === -1) {
            retValue.push(catType);
          }
        }
      });
    return retValue;
  }
  public get allowVGroove(): boolean {
    const state = this.vGrooveState;
    return !state.thinMargins && !state.invalidMat && !state.blackTexturedSurface;
  }

  public get vGrooveState(): any {
    return {
      isDesign: !!this.design,
      thinMargins: this.sizesComplete && this.availableVGrooveOffsets === null,
      invalidMat: !!this.topMat && ((this.topMat.coreType && this.topMat.coreType.toLowerCase() === 'black') ||
      (this.topMat.categoryType && this.topMat.categoryType.toLowerCase().indexOf('suede') > -1)),
      blackTexturedSurface: !!this.topMat && environment.vGroove.disallowedMats.indexOf(this.topMat.code) > -1
    };
  }

  public get sizesComplete(): boolean {
    return this.size && !this.size.incomplete && this.numOpenings === 1 && !this.opening.size.incomplete;
  }

  private calculateAvailableVGrooveOffsets(opening: Opening) {
    const smallerMargin = Math.min(this.size.height - opening.size.height - this.bottomMargin - this.reveal, (this.size.width - opening.size.width) / 2 - this.reveal);
    if (smallerMargin - Project.DEFAULTS.vGrooveOffset.outerEdgeMinOffset >= Project.DEFAULTS.vGrooveOffset.openingEdgeMinOffset) {
      const maxVGrooveOffset = Math.min(Project.DEFAULTS.vGrooveOffset.openingEdgeMaxOffset, smallerMargin - Project.DEFAULTS.vGrooveOffset.outerEdgeMinOffset);
      if (maxVGrooveOffset >= Project.DEFAULTS.vGrooveOffset.openingEdgeMinOffset) {
        this._availableVGrooveOffsets = new SizeRange(Project.DEFAULTS.vGrooveOffset.openingEdgeMinOffset, maxVGrooveOffset, Project.DEFAULTS.vGrooveOffset.vGrooveOffsetIncrement);
      } else {
        this._availableVGrooveOffsets = null;
      }
    } else {
      this._availableVGrooveOffsets = null;
    }
  }

  private adjustingOutersize: boolean = false;
  private updateAvailableOuterSizes() {
    const invalidOuter = function(outerSize: Size, openingSize: Size): boolean {
      const margin = outerSize.ui < environment.minMargins.ui ? environment.minMargins.minUnder : environment.minMargins.minOver;
      return outerSize.width - margin < openingSize.width || outerSize.height - margin < openingSize.height;
    }

    this.size.updateRange(this.opening ? this.opening.size : null, 'opening', this.vGrooveOffset);
    if (this.opening && !this.opening.size.incomplete && (this.size.incomplete || invalidOuter(this.size, this.opening.size))) {
      // calculate a nice margin and use that
      let margin = Project.DEFAULTS.margin.filter( (m:any) => {
        return this.opening.size.ui > m.min && this.opening.size.ui <= m.max;
      })[0].value;
      this.size.width = Math.round(this.opening.size.width + 2 * margin);
      this.size.height = Math.round(this.opening.size.height + 2 * margin);

      // make sure we don't exceed the limits
      if (this.size.width > this.size.height) {
        this.size.width = Math.min(environment.sizeLimits.outer.max, this.size.width);
        this.size.height = Math.min(environment.sizeLimits.outer.secondaryMax, this.size.height);
      } else {
        this.size.width = Math.min(environment.sizeLimits.outer.secondaryMax, this.size.width);
        this.size.height = Math.min(environment.sizeLimits.outer.max, this.size.height);
      }

      // tell system you are adjusting the size programatically (therefore outer still not explicitly set)
      this.adjustingOutersize = true;
    }

    if (this.opening) {
      this.calculateAvailableVGrooveOffsets(this.opening);
      this.calculateAvailableBottomMargins(this.opening);
    }
  }

  private _openings: Array<Opening> = new Array<Opening>();
  get opening(): Opening {
    return this._openings[0];
  }

  get numOpenings(): number {
    return this._openings.length;
  }
  getOpening(i: number): Opening {
    return this._openings[i];
  }

  get isEaselReady(): boolean {
    return environment.easelLimits.availableSizes.indexOf(this.size.toNumericString()) != -1 &&
      (!this.moulding || this.moulding.width <= environment.easelLimits.mouldingWidth);
  }
  get openings() {
    return this._openings;
  }

  addOpening(opening: Opening) {
    opening.change.subscribe( () => {
      this.updateAvailableOuterSizes();
      this.update();
    });
    if (this.print && this._openings.length === 0) {
      opening.print = this.print;
      this.print = null;
    }
    this._openings.push(opening);
    this.updateAvailableOuterSizes();
    this.updateAvailableOpeningSizes();
    this.update();
  }

  private _openingsRemoved:boolean = false;
  clearOpenings() {
    if (this._openings.length === 1 && this._openings[0].print) {
      this.print = this._openings[0].print;
      this._openings[0].print = null;
    }
    this._openings.length = 0;
    this._openingsRemoved = true;
    this.bottomMat = null;
    this.update();
  }

  removeOpening(openingToRemove: Opening) {
    openingToRemove.change.subscribe();
    if (this._openings.length === 1 && openingToRemove.print) {
      this.print = openingToRemove.print;
      openingToRemove.print = null;
    }

    this._openingsRemoved = true;
    this._openings = this._openings.filter( (opening) => {
      return opening !== openingToRemove;
    });

    if (this.numOpenings == 0) {
      this.bottomMat = null;
    }
    this.update();
  }

  private mats:Array<any> = new Array<any>();
  get numMats():number {
    return this.mats.length;
  }
  /*
  addMat(mat:any) {
    this.mats.push(mat);
    this.update();
  }
  clearMats() {
    this.mats.length = 0;
    this.update();
  }
  removeMat(matToRemove:any) {
    this.mats = this.mats.filter( (mat) => {
      return mat !== matToRemove;
    });
    this.update();
  }*/

  private _print: any;
  public get print(): any { return this._print; }
  public set print(val: any) {
    this._print = val;
    this.update();
  }

  get hasPrint(): boolean {
    var result = this._print ? true : false;
    this._openings.forEach( (op) => {
      result = result || op.print;
    });
    return result;
  }


  private _design: Design;
  public get design(): Design { return this._design; }
  public set design(val: Design) {
    if (val == null) {
      this._openings = this.primaryProduct === 'MAT' ? [Opening.INDETERMINATE] : [];
    } else {
      this._restrictions = val.restrictions;
      this._vgrooves = val.vgrooves;
      this._openings = [];
      if (val.openings) {
        const _this = this;
        val.openings.forEach((op: Opening) => {
          const openingSize: Size = new Size(op.size.width, op.size.height);
          const opening = new Opening(openingSize, op.shape ? op.shape : [Shape.DEFAULT]);
          opening.left = op.left;
          opening.top = op.top;
          opening.rotation = op.rotation;
          opening.reveal = op.reveal;
          opening.restrictions = op.restrictions;
          opening.vGrooveOffset = op.vGrooveOffset ? op.vGrooveOffset : 0;
          opening.change.subscribe(() => {
            this.updateAvailableOuterSizes();
            this.update();
          });
          _this._openings.push(opening);
        });
      }

      this._size.width = val.outerSize.width;
      this._size.height = val.outerSize.height;
      if (!this.bottomMatAllowed && this.bottomMat) {
        this.bottomMat = null;
      }
      this._design = val;
    }
    this.update();
  }

  private _permanentDesignId: string;
  public get permanentDesignId(): string { return this._permanentDesignId; };

  get hasConservationMats():boolean {
    if (this.topMat && this.topMat.quality === 'CONSERVATION') return true;
    if (this.bottomMat && this.bottomMat.quality === 'CONSERVATION') return true;
    return false;
  }

  get topMat():any {
    return this.mats[0];
  }
  set topMat(val:any) {
    if (val === null) {
      if (this.bottomMat) {
        this.bottomMat = null;
      }
      this.mats.length = 0;
    } else {
      if (this.mats.length === 0) {
        this.mats.push(val);
      } else {
        this.mats[0] = val;
      }
    }

    this.update();
  }

  get bottomMat():any {
    return this.mats[1];
  }
  set bottomMat(val:any) {
    if (this.mats.length === 0) {
      if (!val) {
        return;
      } else {
        throw new Error('Cant set accent mat if primary mat is not set.');
      }
    }

    if (!val) {
      if (this.mats.length === 2) {
        this.mats.length = 1;
        this.reveal = 0;
        this.updateAvailableOuterSizes();
        this.updateAvailableOpeningSizes();
        this.update();
      }
    } else {
      if (this.mats.length === 1) {
        if (!this._reveal) {
          this.reveal = Project.DEFAULTS.reveal;
        }
        this.mats.push(val);
      } else {
        this.mats[1] = val;
      }
      this.updateAvailableOuterSizes();
      this.updateAvailableOpeningSizes();
      this.update();
    }
  }

  _originalJson: any;
  previewBaseUrl = environment.baseUrls().preview;
  previewShareBaseUrl = environment.baseUrls().previewShare;
  sizeService: SizeService = new SizeService();
  projectService: ProjectService;

  constructor(json: any, projectService: ProjectService = null) {
    // lets not save stuff right away
    this.projectService = projectService;
    this._originalJson = json;
    this._restrictions = json.restrictions;
    if (json.qty) this._qty = json.qty;
    this._reveal = parseFloat(json.reveal ? json.reveal : 0);
    this._moulding = json.moulding;
    this._backingBoard = json.backingBoard;
    this._glazing = json.glazing;
    this._mirror = json.mirror;
    this._bag = json.bag;
    this._primaryProduct = json.primaryProduct;
    this.id = json.id;
    this.originalId = json.originalId;
    this.originalIdForFirstPreview = json.originalIdForFirstPreview;
    this.version = json.version ? json.version : 0;
    this._design = json.design ? new Design(json.design) : (json.designId ? new Design({
      id: json.designId
    }) : null);
    this._permanentDesignId = json.permanentDesignId;
    this._complexityUpCharge = json.complexityUpCharge;
    this._instructions = json.instructions;
    this._vgrooves = json.vgrooves ? json.vgrooves : [];

    this._samples = json.samples ? json.samples : [];
    this._numSamples = json.samples ? json.samples.length : 0;

    this._preConfiguredProductId = json.preConfiguredProductId;

    this.mats.length = 0;
    if (json.topMat && json.topMat.code) {
      this.mats.push(json.topMat);
    }
    if (json.bottomMat && json.bottomMat.code) {
      this.mats.push(json.bottomMat);
    }

    const outerSizeParts = json.outerSize ? json.outerSize.split('x') : [NaN, NaN];
    this._size = new Size(this.sizeService.evalFraction(outerSizeParts[0]), this.sizeService.evalFraction(outerSizeParts[1]));
    this._size.change.subscribe( () => {
      if (this.adjustingOutersize === false) {
        this.updateAvailableOuterSizes();
        this.updateAvailableOpeningSizes();
        this.update();
      }
      this.adjustingOutersize = false;
    });

    if (json.openings) {
      let printIdx = 0;
      json.openings.forEach( (op) => {
        if (op.size.width === 0 && op.size.height === 0) {
          op.size.width = NaN;
          op.size.height = NaN;
        }
        const openingSize: Size = new Size(parseFloat(op.size.width), parseFloat(op.size.height));
        const oval = Shape.fromJson({ code: 'oval', description: 'Oval'});
        const opening = new Opening(openingSize, op.shape ? op.shape : [json.ovalCut ? oval : Shape.DEFAULT]);
        opening.left = op.left;
        opening.top = op.top;
        opening.rotation = op.rotation;
        opening.reveal = op.reveal;
        opening.print = op.print;
        opening.vGrooveOffset = op.vGrooveOffset ? op.vGrooveOffset : 0;
        opening.change.subscribe(() => {
          this.updateAvailableOuterSizes();
          this.update();
        });
        opening.restrictions = op.restrictions;
        this._openings.push(opening);
        printIdx++;
      });
    }

    if (this._openings.length > 0) {
      this._bottomMargin = (this.size.height - this.opening.size.height) / 2 + json.offset;
      this._openings.forEach( (opening: Opening) => {
        opening.offset = this.offset;
        this.calculateAvailableVGrooveOffsets(opening);
        this.calculateAvailableBottomMargins(opening);
      });
    } else {
      this._print = json.print;
    }
    this._seoData = json.seoData;

    this.updateAvailableOuterSizes();
    this.updateAvailableOpeningSizes();
    this.updatePrice(json);
  }

  price:number;
  minPrice:number;
  maxPrice:number;
  updatePrice(json: any) {
    if (typeof json.price !== 'undefined') {
      this.price = json.price;
      this.minPrice = json.minPrice;
      this.maxPrice = json.maxPrice;
    }
  }

  get rotationRestrictions(): any {
    let restrictions = null;
    const minMargin = this.size.ui < environment.minMargins.ui ? environment.minMargins.minUnder : environment.minMargins.minOver;
    if (this.opening) {
      if (this.size.width - this.opening.size.height < minMargin * 2)
      {
        restrictions = {
          outerWidth: true,
          openingHeight: true
        }
      }
      if (this.size.height - this.opening.size.width < minMargin * 2)
      {
        restrictions = Object.assign(restrictions ? restrictions : {}, {
          outerHeight: true,
          openingWidth: true
        });
      }
    }
    return restrictions;
  }

  rotateOuter() {
    this.bottomMargin = NaN;
    this.size.rotate();
  }

  rotateOpening() {
    if (this.opening) {
      this.opening.size.rotate();
      this.calculateAvailableVGrooveOffsets(this.opening);
      this.calculateAvailableBottomMargins(this.opening);
    }
  }

  revealUrl(maxSize: number):string {
    if (this.opening) {
      const opening = this.opening.shape.length === 1 && this.opening.shape[0].code === 'oval' ? '0.5x1.5x-0.29x-0.25:oval' : ('0.5x1.5x-0.29x0:' + this.opening.shape[0].code);
      let result = this.previewBaseUrl + 'moulding=noframe:1x1&openings=' + opening + '&mats=';
      this.mats.forEach( (mat:any, index:number) => {
        result += (index > 0 ? ',' : '') + mat.code + (index > 0 ? ':' + 0.5 : '');
      });

      result += '&max=' + maxSize;
      return result;
    } else {
      return '';
    }
  }

  vGrooveUrl(maxSize: number):string {
    const moulding = this._moulding;
    this._moulding = null;
    let url = this.url(maxSize);
    this._moulding = moulding;
    if (this.opening) {
      let openingSize;
      let size = this.size;
      if (this.opening.size.incomplete) {
        if (size && !size.incomplete) {
          openingSize = Project.ensureRealSize(new Size(size.width * 0.5625, size.height * 0.65));
        } else {
          openingSize = Project.adjustSizeToRatio(Project.DEFAULTS.openingSize, this.opening.shape[0].aspectRatio);
          size = Project.DEFAULTS.outer;
        }
      } else {
        openingSize = this.opening.size;
      }

      const section: any = [
        (size.width - openingSize.width) / 2 - this.reveal - this.vGrooveOffset - 0.25,
        (size.width - openingSize.width) / 2 + this.reveal + this.vGrooveOffset,
        this.vGrooveOffset + 0.5,
        this.vGrooveOffset + 0.5
      ];
      url += '&section=' + section.join('x');
    }
    return url;
  }

  asQueryStringParams(customFill: string = null): string {
    if (this.primaryProduct === 'GC') {
      const bg = this.print && this.print.screenResUrl;
      return 'gc=true&value=$' + this.maxPrice + '&bg=' + (bg ? bg : '333333') + '&textColor=ffffff';
    }
    let result = '';
    if (this.id || this.originalIdForFirstPreview) {
      let printContent = this._openings.map((opening: Opening, index: number) => {
        return opening.print ? opening.print.screenResUrl : '';
      });
      // if we still don't have print content yet we have a print
      if (printContent.length === 0 && this.print) {
        printContent = [this.print.screenResUrl];
      }
      result += 'project=' + (this.id ? this.id : this.originalIdForFirstPreview) + '&printContent=' + printContent.join(',');
    } else {
      const size = this.size.incomplete ? Project.DEFAULTS.outer.urlString : this.size.urlString;

      let mouldingContent = '';
      if (this._openings.length === 0) {
        if (this.print) {
          mouldingContent = ':' + this.print.screenResUrl;
        } else if (this.permanentDesignId) {
          mouldingContent = ':' + environment.baseUrls().site + '/designs/' + this.permanentDesignId + '.jpg';
        }
      }

      result = 'moulding=' + (this.moulding ? this.moulding.code : 'noframe') + ':' + size + (this.mirror ? ':mirror' : mouldingContent)
        + '&offset=' + this.offset
        + '&openings=';

      this._openings.forEach((opening: Opening, index: number) => {
        const originalOpeningFill = opening.fill;
        const fillValue = opening.print ? opening.print.screenResUrl : opening.fill;
        opening.fill = opening.reveal === 0 && this.numMats > 1 ? 'none' : (customFill ? customFill : fillValue);
        let openingSize;

        if (opening.size.incomplete) {
          const pSize = this.size;
          openingSize = ':' + opening.shapeUrlString + ':' + opening.fill;
          if (pSize && !this.size.incomplete) {
            if (opening.shape[0].aspectRatio) {
              openingSize = Project.adjustSizeToRatio(new Size(this.size.width * 0.5625, this.size.height * 0.65), opening.shape[0].aspectRatio).urlString + openingSize;
            } else {
              openingSize = Project.ensureRealSize(new Size(this.size.width * 0.5625, this.size.height * 0.65)).urlString + openingSize;
            }
          } else {
            const sz = Project.adjustSizeToRatio(Project.DEFAULTS.openingSize, opening.shape[0].aspectRatio);
            openingSize = sz.urlString + openingSize;
          }
        } else {
          openingSize = opening.urlString;
        }

        result += (index > 0 ? ',' : '') + openingSize;
        opening.fill = originalOpeningFill;
      });

      result += '&mats=';
      this.mats.forEach((mat: any, index: number) => {
        result += (index > 0 ? ',' : '') + mat.code + (index > 0 ? ':' + this.reveal : (this.vGrooveOffset > 0 ? ':' + this.vGrooveOffset : ''));
      });

      // so we can get the shape of the opening with shadows
      if (this.mats.length === 0 && (this.anyOpeningShapeRequiresShadow || this.hasPrint)) {
        result += 'transparent';
      }
    }

    result += '&pack=' + (this.primaryProduct === 'VALUE-PACK' || this.primaryProduct === 'SHOW-KIT')
    return result;
  }

  shareUrl(background, overlay, download) {
    let result = this.previewShareBaseUrl + background + '/' + this.id;
    let printContent = this._openings.filter(o => o.print).map((opening: Opening, index: number) => {
      return opening.print ? opening.print.screenResUrl.replace('/uploads/','').replace('.jpg','') : '';
    });
    if (printContent.length === 0 && this.print) {
      printContent = [this.print.screenResUrl.replace('/uploads/','').replace('.jpg','')];
    }
    if (printContent.length > 0) {
      result += '-' + printContent.join('-');
    }
    result += '/' + overlay + '.jpg';

    if (download) {
      result += '?dl=true';
    }
    return result;
  }

  _url: any = {};

  url(maxSize: number, customFill: string = null, section: string = null): string {
    const key = `KEY_${maxSize?maxSize:'0'}_${customFill?customFill:'NOFILL'}_${section?section:'NOSECTION'}}`;
    if (this._url[key]) return this._url[key];

    const primaryProductMap: any = {
      'BACKINGBOARD': {
        prop: 'backingBoard',
        previewByCode: true
      },
      'GLAZING': {
        prop: 'glazing',
        previewByCode: true
      },
      'SHOWBAG': {
        prop: 'bag'
      },
      'MIRROR': {
        prop: 'mirror'
      }
    };
    const primaryProduct = primaryProductMap[this.primaryProduct];
    if (primaryProduct && !this.moulding) {
      if (primaryProduct.previewByCode) {
        this._url[key] = '/assets/images/products/' + this.primaryProduct.toLowerCase() + 's' + '/' + this[primaryProduct.prop].code + '.jpg';
      } else {
        this._url[key] = '/assets/images/products/' + this.primaryProduct.toLowerCase() + '.jpg';
      }
    } else {
      let previewUrl = this.previewBaseUrl + this.asQueryStringParams(customFill) + '&max=' + maxSize + (section ? '&section=' + section : '') + '&v=' + this.version;
      if (customFill) {
        previewUrl += '&contentOverride=' + customFill;
      }
      this._url[key] = previewUrl;
    }
    return this._url[key];
  }

  private static roundToSize = function(num: number, step: number = 0.125) {
    num = Math.round(num * 100) / 100;
    const whole = Math.floor(num);
    const frac = num - whole;
    for (let i = 0; i <= 1; i += step) {
      if (frac < i) {
        return Math.floor(num) + i - step;
      }
    }
  };

  public static ensureRealSize(size: Size): Size {
    return new Size(Project.roundToSize(size.width), Project.roundToSize(size.height));
  }

  public static adjustSizeToRatio(size: Size, targetRatio: number): Size {

    if (!targetRatio) {
      targetRatio = this.DEFAULTS.openingSize.width / this.DEFAULTS.openingSize.height;
    }
    const ratio = size.width / size.height;
    if (ratio > targetRatio) {
      return new Size(Project.roundToSize(size.height * targetRatio), Project.roundToSize(size.height));
    } else {
      return new Size(Project.roundToSize(size.width), Project.roundToSize(size.width / targetRatio));
    }
  }

  get canPreview():boolean {
    return (this.hasPrint || this.moulding || this.mats.length > 0 || this.anyOpeningShapeRequiresShadow) && this.numSamples === 0;
  }

  get isEmpty():boolean {
    return !this.moulding && this.mats.length == 0;
  }

  get orientation(): string {
    if (this.size) {
      return this.size.orientation;
    } else {
      return '';
    }
  }

  // basic format that supports what the back-end supports, for now
  toJSON(minimal: boolean = false, stripEmpties: boolean = true): any {
    const retValue: any = {
      frameCode: this.moulding ? this.moulding.code : null,
      mouldingCode: this.moulding ? this.moulding.code : null,
      mirrorCode: this.mirror ? this.mirror.code : null,
      backingBoardCode: this.backingBoard ? this.backingBoard.code : null,
      glazingCode: this.glazing ? this.glazing.code : null,
      topMatCode: this.mats[0] ? this.mats[0].code : null,
      bottomMatCode: this.mats[1] ? this.mats[1].code : null,
      bagCode: this.bag ? this.bag.code : null,
      outerSize: this.size.incomplete ? null : this.size.toNumericString(),
      openings: this._openings.map(op => op.toJson()),
      vgrooves: this._vgrooves,
      reveal: this.reveal && !this.bottomMat ? 0 : this.reveal, // TEMP: check that we don't somehow have a reveal without a bottom mat
      offset: this.offset,
      vGrooveOffset: this.vGrooveOffset,
      qty: this.qty,
      design: this.design ? this.design : null,
      complexityUpCharge: this.complexityUpCharge,
      preConfiguredProductId: this._preConfiguredProductId,
      print: this.hasPrint ? this.print : null
    };

    if (minimal === false) {
      retValue.id = this.id;
      retValue.version = this.version;
      retValue.designId = this.design ? this.design.id : null;
      retValue.permanentDesignId = this.permanentDesignId;
      retValue.instructions = this.instructions;
      retValue.restrictions = this.restrictions;
      retValue.samples = this.numSamples === 0 ? null : this._samples.map( (sample) => {
        return {
          code: sample.code,
          type: sample.type,
          description: sample.description,
          longDescription: sample.longDescription
        };
      });
      retValue.primaryProduct = this.primaryProduct;
      retValue.previewQueryStringParams = this.asQueryStringParams();
    }

    if (stripEmpties === true) {
      for (const prop in retValue) {
        if (retValue[prop] === null) {
          delete retValue[prop];
        }
      }
    }

    return retValue;
  }

  public change: EventEmitter<Project> = new EventEmitter<Project>();

  public updating:boolean = false;
  public longUpdate:boolean = false;
  longUpdateTimer:any;

  public allowUpdates(): void {
    this._updateEnabled = true;
  }

  public triggerChange(): void {
    this.change.emit(this);
  }

  private _updateEnabled = false;
  deferredUpdate:any;
  private update(skipServiceUpdate: boolean = false) {
    if (this.deferredUpdate) {
      clearTimeout(this.deferredUpdate);
    }

    this.deferredUpdate = setTimeout(async () => {
      if (this._updateEnabled && !skipServiceUpdate) {
        this.updating = true;

        // if an old long update timer was set, clear it out
        if (this.longUpdateTimer) {
          clearTimeout(this.longUpdateTimer);
        }

        // let system know a long update is happening
        this.longUpdateTimer = setTimeout(() => {
          this.longUpdate = this.updating;
        }, 500);

        await this.projectService.update(this);
        if (this.longUpdateTimer) {
          clearTimeout(this.longUpdateTimer);
        }

        this.updating = false;
        this.longUpdate = false;

        //remove the saved previews as they are no longer valid due to a change
        this._url = {};
      }

      this.change.emit(this);
    });
  }

  originalHasWarning(warning: string): boolean {
    if (this.originalId) {
      const originalProject = new Project(Object.assign(this._originalJson, {originalId: null}), this.projectService);
      let lastError = null;
      while (true) {
        try {
          originalProject.validate();
          return false;
        } catch (error: any) {
          // just in case we loop when calling this
          if (lastError === error.message) {
            return false;
          }
          lastError = error.message;
          if (error.message !== warning) {
            // clear the error in case it's a warning, and try again
            originalProject._clearedWarnings[error.message] = true;
          } else {
            return true;
          }
        }
      }
    }
    return false;
  }

  // validates the sizes
  validate() {

    if (this.updating) {
      throw new ApplicationError('PROJECT_UPDATING');
    }

    if (this.qty <= 0 && this.numSamples === 0) {
      throw new ApplicationError('NO_QTY');
    }

    if (this.primaryProduct !== 'SAMPLE' && this.size.incomplete) {
      throw new ApplicationError('INCOMPLETE_SIZE');
    }

    if (this.size.longerDimension > environment.sizeLimits.outer.max || this.size.shorterDimension > environment.sizeLimits.outer.secondaryMax) {
      throw new ApplicationError('SIZE_TOO_LARGE');
    }

    if (this.opening && this.opening.size.incomplete && !this.design) {
      throw new ApplicationError('INCOMPLETE_OPENING');
    }

    // if some sort of restrictions apply on the project
    if (this.restrictions) {
      // check if all restrictions are met
      if (this.restrictions.requiredMats && this.restrictions.requiredMats > this.numMats) {
        throw new ApplicationError('MISSING_REQUIRED_MAT');
      }
    }

    if (this.opening) {

      const opening = this._openings[0];
      if (this.opening && !this._clearedWarnings.OPENING_SHAPE_MISMATCH) {
        const shape = this.opening.shape[0];
        if (shape.aspectRatio !== 0) {
          const opRatio = this.opening.size.width / this.opening.size.height;
          const shRatio = shape.aspectRatio;
          const tolerance = Math.abs((opRatio - shRatio) / shRatio);
          if (tolerance > shape.aspectRatioTolerance) {
            throw new ApplicationError('OPENING_SHAPE_MISMATCH', opening);
          }
        }
      }

      const margin = this.size.ui < environment.minMargins.ui ? environment.minMargins.minUnder : environment.minMargins.minOver;
      if (this.size.width - this.opening.size.width - this.reveal * 2 < margin * 2 ||
          this.size.height - this.opening.size.height - this.reveal * 2 - Math.abs(2 * this.offset) < margin * 2) {
        throw new ApplicationError('MARGINS_TOO_THIN');
      }

      // for non oval/rect openings, enforce minimum of 3x3 (exclude custom designs)
      if (!this.design) {
        let hasSmallComplicatedCornerOpening = false;
        this.openings.forEach(op => {
          if (op.shape && op.shape[0] && op.shape[0].code !== 'rect' && op.shape[0].code !== 'oval') {
            if (op.size.width < environment.complicatedCornerMinOpeningSize.width || op.size.height < environment.complicatedCornerMinOpeningSize.height) {
              hasSmallComplicatedCornerOpening = true;
            }
          }
        });
        if (hasSmallComplicatedCornerOpening) {
          throw new ApplicationError('OPENING_TOO_SMALL_FOR_CORNER');
        }
      }
    }

    if (this.primaryProduct === 'MAT' && !this.topMat) {
      throw new ApplicationError('MISSING_REQUIRED_PART');
    }

    if (this.primaryProduct === 'FRAME' && !this.moulding) {
      throw new ApplicationError('MISSING_REQUIRED_PART');
    }

    // frames need a backing board that is thick enough, which is the foam ones
    if (this.moulding && this.backingBoard && this.backingBoard.thickness < environment.minBackingThicknessForFrames) {
      throw new ApplicationError('THIN_BACKING_BOARD');
    }

    if (this.numMats > 0 && this.glazing && this.glazing.code.indexOf('NONGLARE') !== -1) {
      throw new ApplicationError('MAT_WITH_NON_GLARE');
    }

    // double mat core/thickness checks
    if (this.topMat && this.bottomMat) {
      const cores = [this.topMat.coreType.toLowerCase(), this.bottomMat.coreType.toLowerCase()];
      if (cores[0] !== cores[1]) {
        throw new ApplicationError('MAT_CORE_MISMATCH');
      }

      if (this.topMat.thickness !== this.bottomMat.thickness) {
        throw new ApplicationError('MAT_THICKNESS_MISMATCH');
      }
    }

    if (!this.originalHasWarning('OPENING_MATCHES_ARTWORK')) {
      ProjectService.standardSizes.forEach( (size) => {
        if (this.opening) {
          const artworkSize = new Size(size.artwork[0], size.artwork[1]);
          const openingSize = this.opening.size;
          for (let i = 0; i < 2; i++) {
            if (openingSize.equals(artworkSize) && !this._clearedWarnings.OPENING_MATCHES_ARTWORK) {
              throw new ApplicationError('OPENING_MATCHES_ARTWORK');
            }
            artworkSize.rotate();
          }
        }
      });
    }
  }

  private _clearedWarnings: any = {};

  clearWarning(warning:string):void {
    this._clearedWarnings[warning] = true;
  }

  convertOuterSizeToOpening(borderWidth:number = 2) {
    if (this.numOpenings > 0) {
      throw new Error('You can\'t convert the outer size to an opening if you already have an opening');
    }

    const openingSize = new Size(this.size.width - 0.5, this.size.height - 0.5);
    const primaryDim = Math.max(this.size.width + borderWidth * 2, this.size.height + borderWidth * 2);
    const secondaryDim = Math.min(this.size.width + borderWidth * 2, this.size.height + borderWidth * 2);
    if (primaryDim < environment.sizeLimits.outer.max && secondaryDim < environment.sizeLimits.outer.secondaryMax) {
      this.size.width += borderWidth * 2;
      this.size.height += borderWidth * 2;
    } else {
      openingSize.width = null;
      openingSize.height = null;
    }

    this.addOpening(new Opening(openingSize, [Shape.DEFAULT]));
  }

  convertOpeningSizeToOuter(defaultOverlap: number = 0.25) {
    if (this.numOpenings === 0) {
      throw new Error('You can\'t convert the opening size to an outer size if you don\'t have an opening');
    }
    const opSize = this.opening.size;
    this.clearOpenings();
    if (!opSize.incomplete) {
      this.size.width = opSize.width + (defaultOverlap * 2);
      this.size.height = opSize.height + (defaultOverlap * 2);
    }
  }

  get primaryOpenOpening(): Opening
  {
    if (this.openings.length >= 1) {
      return this.openings[0];
    } else {
      return null;
    }
  }

  get anyOpeningShapeRequiresShadow(): boolean {
    var result = false;
    this._openings.forEach( (op) => {
      result = result || (op.shape[0].code !== 'rect' && op.shape[0].code !== 'oval');
    });
    return result;
  }

  get bottomMatAllowed(): boolean {
    if (!this.restrictions) {
      return true;
    }
    return this.restrictions.requiredMats === 0 || this.restrictions.requiredMats === 2;
  }

  get frameHangerType(): string {
    // if we have a moulding, set required hanger type based on size and frame type
    if (this.moulding) {
      // from the available hangers and their limits, pick teh first match
      // saw tooth is the fall back so we should always get a response
      const longSideSize = this.size.incomplete ? 0 : Math.max(this.size.width, this.size.height);
      if (longSideSize > environment.hangerConditions.sawtoothSizeLimit) {
        return 'wire';
      } else {
        if (this.moulding.categoryName === environment.hangerConditions.metalCategoryName) {
          return 'sawtooth-for-metal';
        } else {
          return 'sawtooth';
        }
      }
    } else {
      return null;
    }
  }

  public async idAsync() {
    if (!this.id) {
      await this.projectService.update(this);
    }
    return firstValueFrom(of(this.id));
  }

  private get genericProductDescription() {
    let product = '';
    if (this.primaryProduct === 'MAT') {
      product = this.bottomMat ? 'Double Mat' : 'Mat';
      if (this.moulding) product += ' with Frame'
    } else if (this.primaryProduct === 'FRAME') {
      product = 'Frame';
      if (this.bottomMat) product += ' with Double Mat';
      else if (this.topMat) product += ' with Mat';
    }
    return product;
  }

  public get builderHeading(): string {
    return `${this.size.toString()} ${this.genericProductDescription}`;
  }

  private specificProductDescription() {
    let product = '';
    if (this.primaryProduct === 'MAT') {
      product = this.topMat?.description;
      if (this.bottomMat) product += ` on ${this.bottomMat.description}`;
      if (this.moulding) product += ` with ${this.moulding.description}`;
    } else if (this.primaryProduct === 'FRAME') {
      product = this.moulding?.description;
      if (this.topMat) product += ` with ${this.topMat.description}`;
      if (this.bottomMat) product += ` on ${this.bottomMat.description}`;
    }
    return product ? product : '';
  }

  public get builderSubHeading(): string {
    return `${this.specificProductDescription()}`;
  }

  private metaDescription(): string {

    // get base description for meta tag
    let metaDescription = `${this.size.toString()} ${this.genericProductDescription}`;
    const products = this.primaryProduct === 'MAT' ? [this.topMat, this.bottomMat] : [this.moulding];
    const prodDescription = products.filter(p => p).map(p => p.description).join(' on ');
    if (prodDescription) {
      metaDescription += ` - ${prodDescription}`;
    }

    if (this.primaryProduct === 'MAT') {
      if (this.openings.length >= 1) {
        metaDescription += ` with ${this.openings[0].toString()} opening`
      }
      if (this.bottomMat) {
        metaDescription += ` on ${this.bottomMat.description}`
      }
      const extras = [this.moulding ? `${this.moulding.description} Picture Frame` : null, this.glazing?.description, this.backingBoard?.description, this.bag?.description].filter(d => d);

      if (extras.length > 0) {
        const allButLastExtra = extras.slice(0, extras.length - 1);
        metaDescription += `. Includes ${allButLastExtra.join(', ')}`;
        if (extras.length > 1) {
          metaDescription += ` and ${extras[extras.length - 1]}`;
        }
      }
    }
    else if (this.primaryProduct === 'FRAME') {
      if (this.topMat) {
        metaDescription += ` and ${this.topMat.description}`
      }

      if (this.openings.length >= 1) {
        metaDescription += ` with ${this.openings[0].toString()} opening`
      }

      if (this.bottomMat) {
        metaDescription += ` on ${this.bottomMat.description}`
      }
      const extras = [this.glazing?.description, this.backingBoard?.description, this.bag?.description].filter(d => d);

      if (extras.length > 0) {
        const allButLastExtra = extras.slice(0, extras.length - 1);
        metaDescription += `. Includes ${allButLastExtra.join(', ')}`;
        if (extras.length > 1) {
          metaDescription += ` and ${extras[extras.length - 1]}`;
        }
      }
    }

    const variations = [
      'Unlimited Choice, Fast & Easy. Since 2012, Serving Artists Across the Country. Made in USA',
      'Order online in minutes. Custom Mats in any Size/Color/Quantity. High Quality Mattes, Made in USA',
      'Order online in minutes. Free shipping. Made in USA'
    ];

    let numericProjectId = 0;
    for (let i = 0; i < this.originalIdForFirstPreview?.length; i++) numericProjectId += this.originalIdForFirstPreview.charCodeAt(i);
    metaDescription += `. ${variations[numericProjectId % 3]}`;

    return metaDescription;
  }

  private _seoData: any;
  public get seoData(): any {
    // explicitly set seo wins over autogenerated
    return {
      metaTitle: this._seoData?.metaTitle ?
        this._seoData?.metaTitle :
        `${this.size.toNumericString()} ${this.genericProductDescription} - Custom Matting & Framing`,
      metaDescription: this._seoData?.metaDescription ?
        this._seoData?.metaDescription :
        this.metaDescription()
    };
  }

  public destroy(): void {
    for (let opening of this.openings)
    {
      opening.change.unsubscribe();
    }

    if (this._size) {
      this._size.change.unsubscribe();
    }
  }
}
