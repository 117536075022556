import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, from, of, ReplaySubject, Subject, firstValueFrom} from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { CartItem } from '../components/cart-item/cart-item';
import { Project } from '../models/project';
import {environment} from '../../../environments/environment';
import {WINDOW} from './window.service';
import {CreditCardMaskPipe} from '../pipes/credit-card-mask.pipe';
import {isPlatformBrowser} from '@angular/common';

@Injectable()
export class CartService {
  public static currentCartChanged: ReplaySubject<any> = new ReplaySubject(1);
  public static shippingPromoRequested: Subject<boolean> = new Subject<boolean>();

  cartBaseUrl = environment.baseUrls().api + 'cart/';

  constructor(private httpClient: HttpClient,
              @Inject(WINDOW) private window: Window,
              @Inject(PLATFORM_ID) private platformId: Object) {
  }

  getCart() {
    if (isPlatformBrowser(this.platformId)) {
      return firstValueFrom(
        this.httpClient
          .get(this.cartBaseUrl)
          .pipe(
            map((cart: any) => {
              if (cart.payment && cart.payment.expired) {
                cart.payment = null;
              }
              CartService.currentCartChanged.next(cart);
              return cart;
            })
          )
      );
    } else {
      return firstValueFrom(of(null));
    }
  }

  getCartSummary() {
    return firstValueFrom(
      this.httpClient.get<any>(this.cartBaseUrl + 'summary')
    );
  }

  removeItem(itemId: string) {
    return firstValueFrom(
      this.httpClient
        .delete<any>(this.cartBaseUrl + itemId)
        .pipe(
          map((shoppingCart) => {
            CartService.currentCartChanged.next(shoppingCart);
            return shoppingCart;
          })
        )
    );
  }

  empty() {
    return firstValueFrom(
      this.httpClient
        .delete<any>(this.cartBaseUrl)
        .pipe(
          map((shoppingCart) => {
            CartService.currentCartChanged.next(shoppingCart);
            return shoppingCart;
          })
        )
    );
  }

  updateCartItem(item: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return firstValueFrom(
      this.httpClient
        .patch(this.cartBaseUrl + item.id , item, {headers: headers})
        .pipe(
          map((shoppingCart) => {
            CartService.currentCartChanged.next(shoppingCart);
            return shoppingCart;
          })
        )
    );
  }

  updateCartItemQty(item: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return firstValueFrom(
      this.httpClient
        .patch(this.cartBaseUrl + item.id + '/' + item.qty, null, {headers: headers})
        .pipe(
          map((shoppingCart) => {
            CartService.currentCartChanged.next(shoppingCart);
            return shoppingCart;
          })
        )
    );
  }

  getShippingOptions(address: any) {
    // default county to US
    address.country = address.country ? address.country : 'US';

    // second addressline should be null if it's not entered
    address.addressLine2 = address.addressLine2 ? address.addressLine2 : null;

    return firstValueFrom(
      this.httpClient.post<any>(this.cartBaseUrl + 'shipping', address)
    );
  }

  updateShippingService(shippingOption: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return firstValueFrom(
      this.httpClient.patch<any>(this.cartBaseUrl + 'shipping', JSON.stringify(shippingOption), {headers: headers})
    );
  }

  updatePayment(payment: any) {
    return firstValueFrom(
      this.httpClient.patch<any>(this.cartBaseUrl + 'payment', payment)
    );
  }

  addItemsToCart(items: Array<any>) {
    items.forEach(item => {
      // strip any ids so it doesn't think u're editing an existing item
      if (item.id) {
        delete item.id;
      }
    });

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return firstValueFrom(
      this.httpClient
        .post(this.cartBaseUrl, items, { headers: headers})
        .pipe(
          map((shoppingCart: any) => {
            CartService.currentCartChanged.next(shoppingCart);
            return shoppingCart;
          })
        )
    );
  }

  addProject(project: Project) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return firstValueFrom(
      this.httpClient
        .post(this.cartBaseUrl, [project.toJSON()], { headers: headers})
        .pipe(
          map((shoppingCart: any) => {
            CartService.currentCartChanged.next(shoppingCart);
            return shoppingCart;
          })
        )
    );
  }

  public checkout(shoppingCart) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const cart = Object.assign({}, shoppingCart);
    if (!cart.payment || !cart.payment.hint) {
      cart.payment = {
        hint: {
          paymentMethod: 'not-provided'
        }
      };
    }

    return firstValueFrom(
      this.httpClient.post<any>(this.cartBaseUrl + 'checkout', JSON.stringify(cart), {headers: headers})
    );
  }

  checkAddress(address:any):boolean {
    return address && address.fullname && address.addressLine1 && address.city && address.state && address.zipCode && address.zipCode.length === 5;
  }

  checkPayment(payment:any):boolean {
    return payment && payment.fullname && payment.ccNumber && payment.securityCode && payment.expMonth && payment.expYear;
  }

  setCartItemExtras(orderline: CartItem): void {
    if (orderline) {

      // set thumb url
      if (orderline.permanentDesignId && (!orderline.openings || orderline.openings.length <= 1)) {
        orderline.thumbUrl = environment.baseUrls().site + '/designs/' + orderline.permanentDesignId + '.jpg';
        orderline.thumbNeedsShadow = false;
      } else {
        const project = new Project(orderline);
        orderline.thumbUrl = project.url(250, 'checkered', null);
        // only add modifier to generated previews
        orderline.thumbNeedsShadow = orderline.thumbUrl.indexOf('preview') !== -1 && orderline.thumbUrl.indexOf('pack=true') === -1;
      }

      ['topMat', 'bottomMat' ,'moulding', 'bag', 'backingBoard', 'glazing'].forEach((partName) => {
        if (orderline[partName] && !orderline[partName].active) orderline[partName].inactiveDescriptor = "INACTIVE";
      })
    }
  }
}
